import {
  addNewKeyPairInArray,
  convertFormdataToJson,
  getSyncedData,
  hasPendingHoldContacts,
  normalizeProcessQueueData,
} from "../../helpers";
import { CCB_TO_MC, GROUPS, SEARCH } from "../../utils/integrationsConstants";
import { ACTION_TYPES } from "../actions/types";
import lodash from "lodash";

const initialState = {
  showSearchFor: "",
  currentSync: CCB_TO_MC,

  // CCB State
  ccbCampusesStatus: "",
  ccbCampuses: [],
  syncedCcbCampuses: [],

  ccbGroupStatus: "",
  ccbGroups: [],

  // Process queue
  ccbProcessQueueStatus: "",
  ccbProcessQueues: [],

  ccbSavedSearchesStatus: "",
  ccbSavedSearches: [],
  ccbSyncedSavedsearches: [],

  mcToCcbpairsStatus: "",
  mcToCcbpairs: [],

  ccbCampusesAndSavedSearches: [],

  saveCcbToMcSyncPairStatus: "",

  enableTwoWaySyncingStatus: "",
  enableTwoWaySyncingFor: "",
  enableTwoWaySyncingErrorMsg: "",

  // Remove ccb pair state
  removeCcbToMcPairStatus: "",

  // Mailchimp state
  mailChimpAudienceStatus: "",
  mailChimpAudience: [],

  mailchimpInterestedGroups: [],
  mailchimpInterestedGroupsStatus: "",
  mailchimplistIdForInterestedGroups: [],

  saveMcToCcbSyncPairStatus: "",

  mailchimpTags: {},
  mailchimpTagsStatus: "",
  mailchimpListIdForTags: [],

  // Remove MC pair state
  removeMcToCcbPairStatus: "",

  // REMOVE MULTIPLE MC TO CCB PAIR STATE
  removeMultipleMcToCcbPairStatus: "",

  // REMOVE MULTIPLE CCB TO MC PAIR
  removeMultipleCcbToMcPairStatus: "",

  // Main tabs switch buttons
  isCcbToMcIntegrationEnabled: true,
  isMcToCcbIntegrationEnabled: false,

  // Remove sync pair modal
  showRemoveSyncPairModal: false,

  // Sync confirmation modal
  showSyncConfirmationModal: false,

  // Sync new pairs confirmation modal
  showSyncNewPairConfirmationModal: false,
  showSyncNewPairSkipRoute: "",

  // Syncing progress bar
  showSyncingProgress: false,

  // Add new sync pair modal
  showAddNewSyncPairModal: false,

  // SYNC MANUAL
  syncManualStatus: "",
  checkSyncStatus: null,
  syncManualMessage: "",

  // SYNC NEW PAIRS
  syncNewPairsStatus: "",

  syncingInProgress: true,

  syncPairHoverId: null,

  syncJobAddedToQueue: false,

  // Refresh list
  refreshListStatus: null,

  // Unresolve conflicts pairs
  unresolveConflictsPairsStatus: null,
  ccbToMcConflictsPairs: [],
  mcToCcbConflictsPairs: [],

  // Hold contacts
  holdContactsStatus: null,
  holdContacts: [],

  // Possible matches
  possibleMatchesStatus: null,
  possibleMatches: [],

  // MATCH CONTACTS
  matchContactsStatus: null,

  // IGNORE HOLD CONTACTS
  ignoreHoldContactsStatus: null,
  ignoreHoldContactId: null,

  // IGNORE ALL HOLD CONTACTS
  ignoreAllHoldContactsStatus: null,

  // CANCEL SYNC
  stopSyncStatus: null,

  // TWO WAY SYNCING
  isTwoWaySyncingEnabled: null,

  // DISABLE TWO WAY SYNCING
  disableTwoWaySyncingStatus: null,
  disableTwoWaySyncingFor: "",

  // Has not synced pairs
  hasNotSyncedPairs: false,

  // Has saved search permissions
  hasSavedSearchPermissions: false,
  hasSavedSearchPermissionsStatus: "",

  // Error message
  errorMessage: "",

  // MC to CCB disable inactive remove
  mcToCcbDisableInactiveRemoveFor: {},

  // Integration pair for edit
  integrationPairForEdit: {},
};

const integrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_CCB_CAMPUSES: {
      return {
        ...state,
        ccbCampusesStatus: "loading",
      };
    }

    case ACTION_TYPES.GET_CCB_CAMPUSES_SUCCESS: {
      const updatedData = addNewKeyPairInArray(action.payload.data, {
        groupBy: "Campuses",
      });
      const savedSearches = [
        { name: "Saved Searches", groupBy: "Saved Searches" },
      ];
      const campuses =
        state.currentSync === CCB_TO_MC
          ? [...updatedData, ...savedSearches]
          : updatedData;
      return {
        ...state,
        ccbCampusesStatus: "success",
        ccbCampuses: updatedData,
        syncedCcbCampuses: getSyncedData(updatedData),
        ccbCampusesAndSavedSearches: campuses,
      };
    }

    case ACTION_TYPES.GET_CCB_CAMPUSES_ERROR: {
      return {
        ...state,
        ccbCampusesStatus: "error",
      };
    }

    case ACTION_TYPES.GET_CCB_SAVED_SEARCHES: {
      return {
        ...state,
        ccbSavedSearchesStatus: "loading",
      };
    }

    case ACTION_TYPES.GET_CCB_SAVED_SEARCHES_SUCCESS: {
      const populateGroupByInAdvanceSearches = addNewKeyPairInArray(
        action.payload.advance,
        {
          groupBy: "New Advanced Searches",
        }
      );
      const populateGroupByInClassicSearches = addNewKeyPairInArray(
        action.payload.classic,
        {
          groupBy: "Classic Searches",
        }
      );
      const finalSavedSearches = [
        ...populateGroupByInAdvanceSearches,
        ...populateGroupByInClassicSearches,
      ];
      return {
        ...state,
        ccbSavedSearchesStatus: "success",
        ccbSavedSearches: finalSavedSearches,
        ccbSyncedSavedsearches: getSyncedData(finalSavedSearches),
      };
    }

    case ACTION_TYPES.GET_CCB_SAVED_SEARCHES_ERROR: {
      return {
        ...state,
        ccbSavedSearchesStatus: "error",
      };
    }

    case ACTION_TYPES.GET_MC_TO_CCB_PAIRS: {
      return {
        ...state,
        mcToCcbpairsStatus: "loading",
      };
    }

    case ACTION_TYPES.GET_MC_TO_CCB_PAIRS_SUCCESS: {
      return {
        ...state,
        mcToCcbpairsStatus: "success",
        mcToCcbpairs: action.payload.data,
      };
    }

    case ACTION_TYPES.GET_MC_TO_CCB_PAIRS_ERROR: {
      return {
        ...state,
        mcToCcbpairsStatus: "error",
      };
    }

    case ACTION_TYPES.GET_MAILCHIMP_AUDIENCE: {
      return {
        ...state,
        mailChimpAudienceStatus: "loading",
      };
    }

    case ACTION_TYPES.GET_MAILCHIMP_AUDIENCE_SUCCESS: {
      return {
        ...state,
        mailChimpAudienceStatus: "success",
        mailChimpAudience: action.payload.data,
      };
    }

    case ACTION_TYPES.GET_MAILCHIMP_AUDIENCE_ERROR: {
      return {
        ...state,
        mailChimpAudienceStatus: "error",
      };
    }

    case ACTION_TYPES.GET_MAILCHIMP_AUDIENCE_CLEAR: {
      return {
        ...state,
        mailChimpAudienceStatus: "",
        mailChimpAudience: [],
      };
    }

    case ACTION_TYPES.REMOVE_CCB_TO_MC_SYNC_PAIR: {
      return {
        ...state,
        removeCcbToMcPairStatus: "loading",
      };
    }

    case ACTION_TYPES.REMOVE_CCB_TO_MC_SYNC_PAIR_SUCCESS: {
      let allCcbGroups = state.ccbGroups;
      let allCcbProcessQueues = state.ccbProcessQueues;
      let removePairFormData = convertFormdataToJson(action?.data);
      if (action.payload?.status === 1) {
        if (
          !lodash.isEmpty(allCcbProcessQueues) &&
          removePairFormData?.type === "process_queue"
        ) {
          allCcbProcessQueues = allCcbProcessQueues.filter(
            (item) => action?.id !== item.id
          );
        }
        if (
          !lodash.isEmpty(allCcbGroups) &&
          removePairFormData?.type === "group"
        ) {
          allCcbGroups = allCcbGroups.filter((item) => action.id !== item.id);
        }
      }
      return {
        ...state,
        removeCcbToMcPairStatus: action?.isFromEditPair ? "" : "success",
        ccbCampuses: action.isRemoveFromBrokenPairModal
          ? state.ccbCampuses
          : [],
        ccbSavedSearches: action.isRemoveFromBrokenPairModal
          ? state.ccbSavedSearches
          : [],
        syncedCcbCampuses: action.isRemoveFromBrokenPairModal
          ? state.syncedCcbCampuses
          : [],
        ccbSyncedSavedsearches: action.isRemoveFromBrokenPairModal
          ? state.ccbSyncedSavedsearches
          : [],
        ccbGroups: lodash.uniqBy(allCcbGroups, "id"),
        ccbProcessQueues: lodash.uniqBy(allCcbProcessQueues, "id"),
      };
    }

    case ACTION_TYPES.REMOVE_CCB_TO_MC_SYNC_PAIR_ERROR: {
      return {
        ...state,
        removeCcbToMcPairStatus: "error",
      };
    }

    case ACTION_TYPES.REMOVE_CCB_TO_MC_SYNC_PAIR_CLEAR: {
      return {
        ...state,
        removeCcbToMcPairStatus: "",
      };
    }

    case ACTION_TYPES.REMOVE_MC_TO_CCB_SYNC_PAIR: {
      return {
        ...state,
        removeMcToCcbPairStatus: "loading",
      };
    }

    case ACTION_TYPES.REMOVE_MC_TO_CCB_SYNC_PAIR_SUCCESS: {
      return {
        ...state,
        removeMcToCcbPairStatus: action?.isFromEditPair ? "" : "success",
        mcToCcbpairs: action.isRemoveFromBrokenPairModal
          ? state.mcToCcbpairs
          : [],
      };
    }

    case ACTION_TYPES.REMOVE_MC_TO_CCB_SYNC_PAIR_ERROR: {
      return {
        ...state,
        removeMcToCcbPairStatus: "error",
      };
    }

    case ACTION_TYPES.REMOVE_MC_TO_CCB_SYNC_PAIR_CLEAR: {
      return {
        ...state,
        removeMcToCcbPairStatus: "",
      };
    }

    case ACTION_TYPES.SET_SHOW_SEARCH_FOR: {
      return {
        ...state,
        showSearchFor: action.payload,
      };
    }

    case ACTION_TYPES.CHANGE_SYNC_TYPE: {
      return {
        ...state,
        currentSync: action.payload,
      };
    }

    case ACTION_TYPES.TOGGLE_SWITCH_CCB_TO_MC: {
      return {
        ...state,
        isCcbToMcIntegrationEnabled: action.payload,
      };
    }

    case ACTION_TYPES.TOGGLE_SWITCH_MC_TO_CCB: {
      return {
        ...state,
        isMcToCcbIntegrationEnabled: action.payload,
      };
    }

    case ACTION_TYPES.SHOW_REMOVE_SYNC_PAIR_MODAL: {
      return {
        ...state,
        showRemoveSyncPairModal: action.payload,
      };
    }

    case ACTION_TYPES.SHOW_SYNC_CONFIRMATION_MODAL: {
      return {
        ...state,
        showSyncConfirmationModal: action.payload,
      };
    }

    case ACTION_TYPES.SHOW_NEW_SYNC_PAIRS_CONFIRMATION_MODAL: {
      return {
        ...state,
        showSyncNewPairConfirmationModal: action.payload,
        showSyncNewPairSkipRoute: action.skipRoue || "",
      };
    }

    case ACTION_TYPES.SHOW_SYNCING_PROGRESS: {
      return {
        ...state,
        showSyncingProgress: action.payload,
      };
    }

    case ACTION_TYPES.SHOW_ADD_NEW_SYNC_PAIRS_MODAL: {
      return {
        ...state,
        showAddNewSyncPairModal: action.payload,
      };
    }

    case ACTION_TYPES.SAVE_CCB_TO_MC_SYNC_PAIR: {
      return {
        ...state,
        saveCcbToMcSyncPairStatus: "loading",
      };
    }

    case ACTION_TYPES.SAVE_CCB_TO_MC_SYNC_PAIR_SUCCESS: {
      let allCcbGroups = state.ccbGroups;
      let allCcbProcessQueues = state.ccbProcessQueues;
      if (!lodash.isEmpty(allCcbGroups)) {
        allCcbGroups = allCcbGroups.filter(
          (item) => !action.ids?.includes(item.campusId)
        );
      }
      if (!lodash.isEmpty(allCcbProcessQueues)) {
        allCcbProcessQueues = allCcbProcessQueues.filter(
          (item) => !action.ids?.includes(item?.processList?.campusId)
        );
      }
      return {
        ...state,
        saveCcbToMcSyncPairStatus: "success",
        ccbCampuses: action.isFixingBrokenPairs ? state.ccbCampuses : [],
        ccbSavedSearches: action.isFixingBrokenPairs
          ? state.ccbSavedSearches
          : [],
        syncedCcbCampuses: action.isFixingBrokenPairs
          ? state.syncedCcbCampuses
          : [],
        ccbSyncedSavedsearches: action.isFixingBrokenPairs
          ? state.ccbSyncedSavedsearches
          : [],
        ccbGroups: lodash.uniqBy(allCcbGroups, "id"),
        ccbProcessQueues: lodash.uniqBy(allCcbProcessQueues, "id"),
      };
    }

    case ACTION_TYPES.SAVE_CCB_TO_MC_SYNC_PAIR_ERROR: {
      return {
        ...state,
        saveCcbToMcSyncPairStatus: "error",
      };
    }

    case ACTION_TYPES.SAVE_CCB_TO_MC_SYNC_PAIR_CLEAR: {
      return {
        ...state,
        saveCcbToMcSyncPairStatus: "",
      };
    }

    case ACTION_TYPES.SAVE_MC_TO_CCB_SYNC_PAIR: {
      return {
        ...state,
        saveMcToCcbSyncPairStatus: "loading",
      };
    }

    case ACTION_TYPES.SAVE_MC_TO_CCB_SYNC_PAIR_SUCCESS: {
      return {
        ...state,
        saveMcToCcbSyncPairStatus: "success",
        mcToCcbpairs: action.isFixingBrokenPairs ? state.mcToCcbpairs : [],
      };
    }

    case ACTION_TYPES.SAVE_MC_TO_CCB_SYNC_PAIR_ERROR: {
      return {
        ...state,
        saveMcToCcbSyncPairStatus: "error",
      };
    }

    case ACTION_TYPES.SAVE_MC_TO_CCB_SYNC_PAIR_CLEAR: {
      return {
        ...state,
        saveMcToCcbSyncPairStatus: "",
      };
    }

    case ACTION_TYPES.SYNC_MANUAL: {
      return {
        ...state,
        syncManualStatus: "loading",
        syncingInProgress: false,
      };
    }

    case ACTION_TYPES.SYNC_MANUAL_SUCCESS: {
      return {
        ...state,
        syncManualStatus: action.payload?.status === 0 ? "" : "success",
        syncingInProgress: action.payload?.status === 1,
        syncManualMessage: action.payload.message,
      };
    }

    case ACTION_TYPES.SYNC_MANUAL_ERROR: {
      return {
        ...state,
        syncManualStatus: "error",
        syncingInProgress: false,
        syncManualMessage: action.payload.message,
      };
    }

    case ACTION_TYPES.SYNC_ONLY_NEW_PAIRS: {
      return {
        ...state,
        syncNewPairsStatus: "loading",
      };
    }

    case ACTION_TYPES.SYNC_ONLY_NEW_PAIRS_SUCCESS: {
      return {
        ...state,
        syncNewPairsStatus: "success",
        syncManualStatus: "success",
        syncingInProgress: true,
      };
    }

    case ACTION_TYPES.SYNC_ONLY_NEW_PAIRS_ERROR: {
      return {
        ...state,
        syncNewPairsStatus: "error",
      };
    }

    case ACTION_TYPES.ENABLE_TWO_WAY_SYNCING: {
      return {
        ...state,
        enableTwoWaySyncingStatus: "loading",
        enableTwoWaySyncingFor: action.switchIdentity,
      };
    }

    case ACTION_TYPES.ENABLE_TWO_WAY_SYNCING_SUCCESS: {
      return {
        ...state,
        enableTwoWaySyncingStatus:
          action.payload.status === 1 ? "success" : "error",
        enableTwoWaySyncingFor: action.switchIdentity,
        isTwoWaySyncingEnabled: action.payload.status === 1,
        isCcbToMcIntegrationEnabled: true,
        isMcToCcbIntegrationEnabled: action.payload.status === 1,
        enableTwoWaySyncingErrorMsg:
          action.payload.status === 0
            ? action.payload.message || action.payload?.error
            : "",
      };
    }

    case ACTION_TYPES.ENABLE_TWO_WAY_SYNCING_ERROR: {
      return {
        ...state,
        enableTwoWaySyncingStatus: "error",
        isTwoWaySyncingEnabled: null,
        enableTwoWaySyncingFor: "",
        enableTwoWaySyncingErrorMsg:
          action.payload.status === 0
            ? "Something went wrong, Please try again later."
            : "",
      };
    }

    case ACTION_TYPES.ENABLE_TWO_WAY_SYNCING_CLEAR: {
      return {
        ...state,
        enableTwoWaySyncingStatus: null,
        enableTwoWaySyncingFor: "",
        enableTwoWaySyncingErrorMsg: "",
      };
    }

    case ACTION_TYPES.DISABLE_TWO_WAY_SYNCING: {
      return {
        ...state,
        disableTwoWaySyncingStatus: "loading",
        disableTwoWaySyncingFor: action.switchIdentity,
      };
    }

    case ACTION_TYPES.DISABLE_TWO_WAY_SYNCING_SUCCESS: {
      state[action.switchIdentity] = !state[action.switchIdentity];
      return {
        ...state,
        disableTwoWaySyncingStatus: "success",
        isTwoWaySyncingEnabled: action.payload.status === 1,
        disableTwoWaySyncingFor: action.switchIdentity,
      };
    }

    case ACTION_TYPES.DISABLE_TWO_WAY_SYNCING_ERROR: {
      return {
        ...state,
        disableTwoWaySyncingStatus: "error",
        isTwoWaySyncingEnabled: null,
        disableTwoWaySyncingFor: "",
      };
    }

    case ACTION_TYPES.DISABLE_TWO_WAY_SYNCING_CLEAR: {
      return {
        ...state,
        disableTwoWaySyncingStatus: null,
        disableTwoWaySyncingFor: "",
      };
    }

    case ACTION_TYPES.CHECK_SYNC_SUCCESS: {
      return {
        ...state,
        syncingInProgress: [0, 2].includes(action.payload.status)
          ? true
          : false,
        syncManualStatus: [0, 2].includes(action.payload.status)
          ? "success"
          : "",
        checkSyncStatus: action.payload.status,
        syncJobAddedToQueue: action.payload.status === 2,
      };
    }

    case ACTION_TYPES.CHECK_SYNC_CLEAR: {
      return {
        ...state,
        checkSyncStatus: null,
        ccbGroupStatus:
          Number(state.checkSyncStatus) === 1 ? "" : state.ccbGroupStatus,
        ccbGroups: Number(state.checkSyncStatus) === 1 ? [] : state.ccbGroups,
        ccbProcessQueues:
          Number(state.checkSyncStatus) === 1 ? [] : state.ccbProcessQueues,
        mailchimpInterestedGroups:
          Number(state.checkSyncStatus) === 1
            ? []
            : state.mailchimpInterestedGroups,
        mailchimpInterestedGroupsStatus:
          Number(state.checkSyncStatus) === 1
            ? ""
            : state.mailchimpInterestedGroupsStatus,
        mailchimplistIdForInterestedGroups:
          Number(state.checkSyncStatus) === 1
            ? []
            : state.mailchimplistIdForInterestedGroups,
      };
    }

    case ACTION_TYPES.ADD_TO_QUEUE_CLEAR: {
      return {
        ...state,
        syncJobAddedToQueue: false,
      };
    }

    case ACTION_TYPES.SYNC_MANUAL_CLEAR: {
      return {
        ...state,
        syncManualStatus: "",
        syncingInProgress: true,
      };
    }

    case ACTION_TYPES.CLEAR_INTEGRATION_PAGE_STATE: {
      return {
        ...state,
        saveCcbToMcSyncPairStatus: "",
        saveMcToCcbSyncPairStatus: "",
        removeCcbToMcPairStatus: "",
        removeMcToCcbPairStatus: "",
      };
    }

    case ACTION_TYPES.HOVER_INTEGRATION_ROW: {
      return {
        ...state,
        syncPairHoverId: action.payload,
      };
    }

    case ACTION_TYPES.REFRESH_LIST: {
      return {
        ...state,
        refreshListStatus: "loading",
      };
    }

    case ACTION_TYPES.REFRESH_LIST_SUCCESS: {
      return {
        ...state,
        refreshListStatus: "success",
      };
    }

    case ACTION_TYPES.REFRESH_LIST_ERROR: {
      return {
        ...state,
        refreshListStatus: "error",
      };
    }

    case ACTION_TYPES.REFRESH_LIST_CLEAR: {
      return {
        ...state,
        refreshListStatus: null,
      };
    }

    case ACTION_TYPES.GET_UNRESOLVE_CONFLICTS_PAIRS: {
      return {
        ...state,
        unresolveConflictsPairsStatus: "loading",
      };
    }

    case ACTION_TYPES.GET_UNRESOLVE_CONFLICTS_PAIRS_SUCCESS: {
      const ccbToMcPairs = hasPendingHoldContacts(action.payload?.ccb_to_mc);
      const mcToCcbPairs = hasPendingHoldContacts(action.payload?.mc_to_ccb);
      return {
        ...state,
        unresolveConflictsPairsStatus: "success",
        ccbToMcConflictsPairs: ccbToMcPairs,
        mcToCcbConflictsPairs: mcToCcbPairs,
      };
    }

    case ACTION_TYPES.GET_UNRESOLVE_CONFLICTS_PAIRS_ERROR: {
      return {
        ...state,
        unresolveConflictsPairsStatus: "error",
      };
    }

    case ACTION_TYPES.GET_HOLD_CONTACTS: {
      return {
        ...state,
        holdContactsStatus: "loading",
      };
    }

    case ACTION_TYPES.GET_HOLD_CONTACTS_SUCCESS: {
      return {
        ...state,
        holdContactsStatus: "success",
        holdContacts: action.payload?.holdContacts,
      };
    }

    case ACTION_TYPES.GET_HOLD_CONTACTS_ERROR: {
      return {
        ...state,
        holdContactsStatus: "error",
      };
    }

    case ACTION_TYPES.GET_POSSIBLE_MATCHES: {
      return {
        ...state,
        possibleMatchesStatus: "loading",
      };
    }

    case ACTION_TYPES.GET_POSSIBLE_MATCHES_SUCCESS: {
      return {
        ...state,
        possibleMatchesStatus: "success",
        possibleMatches: action.payload?.possibleMatches,
      };
    }

    case ACTION_TYPES.GET_POSSIBLE_MATCHES_ERROR: {
      return {
        ...state,
        possibleMatchesStatus: "error",
      };
    }

    case ACTION_TYPES.MATCH_CONTACTS: {
      return {
        ...state,
        matchContactsStatus: "loading",
      };
    }

    case ACTION_TYPES.MATCH_CONTACTS_SUCCESS: {
      return {
        ...state,
        matchContactsStatus:
          Number(action.payload.status) === 1 ? "success" : "error",
        errorMessage:
          Number(action.payload.status) === 0 ? action.payload?.message : "",
      };
    }

    case ACTION_TYPES.MATCH_CONTACTS_ERROR: {
      return {
        ...state,
        matchContactsStatus: "error",
      };
    }

    case ACTION_TYPES.MATCH_CONTACTS_CLEAR: {
      return {
        ...state,
        matchContactsStatus: null,
        errorMessage: "",
      };
    }

    case ACTION_TYPES.IGNORE_HOLD_CONTACTS: {
      const holdcontactId = convertFormdataToJson(action.data);
      return {
        ...state,
        ignoreHoldContactsStatus: "loading",
        ignoreHoldContactId: holdcontactId.id,
      };
    }

    case ACTION_TYPES.IGNORE_HOLD_CONTACTS_SUCCESS: {
      return {
        ...state,
        ignoreHoldContactsStatus:
          Number(action.payload.status) === 1 ? "success" : "error",
        errorMessage:
          Number(action.payload.status) === 0 ? action.payload?.message : "",
      };
    }

    case ACTION_TYPES.IGNORE_HOLD_CONTACTS_ERROR: {
      return {
        ...state,
        ignoreHoldContactsStatus: "error",
        ignoreHoldContactId: null,
        errorMessage: "",
      };
    }

    case ACTION_TYPES.IGNORE_HOLD_CONTACTS_CLEAR: {
      return {
        ...state,
        ignoreHoldContactsStatus: null,
        ignoreHoldContactId: null,
        errorMessage: "",
      };
    }

    case ACTION_TYPES.IGNORE_ALL_HOLD_CONTACTS: {
      return {
        ...state,
        ignoreAllHoldContactsStatus: "loading",
      };
    }

    case ACTION_TYPES.IGNORE_ALL_HOLD_CONTACTS_SUCCESS: {
      return {
        ...state,
        ignoreAllHoldContactsStatus:
          Number(action.payload.status) === 1 ? "success" : "error",
      };
    }

    case ACTION_TYPES.IGNORE_ALL_HOLD_CONTACTS_ERROR: {
      return {
        ...state,
        ignoreAllHoldContactsStatus: "error",
      };
    }

    case ACTION_TYPES.IGNORE_ALL_HOLD_CONTACTS_CLEAR: {
      return {
        ...state,
        ignoreAllHoldContactsStatus: null,
      };
    }

    case ACTION_TYPES.STOP_SYNC: {
      return {
        ...state,
        stopSyncStatus: "loading",
      };
    }

    case ACTION_TYPES.STOP_SYNC_SUCCESS: {
      return {
        ...state,
        stopSyncStatus:
          Number(action.payload.status) === 1 ? "success" : "error",
      };
    }

    case ACTION_TYPES.STOP_SYNC_ERROR: {
      return {
        ...state,
        stopSyncStatus: "error",
      };
    }

    case ACTION_TYPES.STOP_SYNC_CLEAR: {
      return {
        ...state,
        stopSyncStatus: null,
      };
    }

    case ACTION_TYPES.SET_MAILCHIMP_INTERESTED_GROUPS: {
      return {
        ...state,
        mailchimpInterestedGroupsStatus: "loading",
      };
    }

    case ACTION_TYPES.SET_MAILCHIMP_INTERESTED_GROUPS_SUCCESS: {
      const formData = convertFormdataToJson(action.data);
      let concatenateMcGroups;
      const mailchimpInteredGroupIds = state.mailchimplistIdForInterestedGroups;
      if (action.payload?.status === 1) {
        concatenateMcGroups = [
          ...state.mailchimpInterestedGroups,
          ...action.payload.data,
        ];
        mailchimpInteredGroupIds.push(formData.mailchimpListId);
      } else {
        concatenateMcGroups = [...state.mailchimpInterestedGroups];
      }
      const filterMcInterestedGroups = lodash.uniqBy(
        concatenateMcGroups,
        "uniqueId"
      );
      return {
        ...state,
        mailchimpInterestedGroups: filterMcInterestedGroups,
        mailchimpInterestedGroupsStatus: "success",
        mailchimplistIdForInterestedGroups: [
          ...new Set(mailchimpInteredGroupIds),
        ],
      };
    }

    case ACTION_TYPES.SET_MAILCHIMP_INTERESTED_GROUPS_ERROR: {
      return {
        ...state,
        mailchimpInterestedGroupsStatus: "error",
      };
    }

    case ACTION_TYPES.SET_CCB_GROUP: {
      return {
        ...state,
        ccbGroupStatus: "loading",
      };
    }

    case ACTION_TYPES.SET_CCB_GROUP_SUCCESS: {
      let concatenateCcbGroups;
      if (action.payload?.status === 1) {
        concatenateCcbGroups = [...state.ccbGroups, ...action.payload.data];
      } else {
        concatenateCcbGroups = [...state.ccbGroups];
      }
      const filterCcbGroups = lodash.uniqBy(concatenateCcbGroups, "id");
      return {
        ...state,
        ccbGroups: filterCcbGroups,
        ccbGroupStatus: "success",
      };
    }

    case ACTION_TYPES.SET_CCB_GROUP_ERROR: {
      return {
        ...state,
        ccbGroupStatus: "error",
      };
    }

    case ACTION_TYPES.SET_CCB_PROCESS_QUEUES: {
      return {
        ...state,
        ccbProcessQueueStatus: "loading",
      };
    }

    case ACTION_TYPES.SET_CCB_PROCESS_QUEUES_SUCCESS: {
      let concatenateCcbProcessQueue;
      if (action.payload?.status === 1) {
        concatenateCcbProcessQueue = [
          ...state.ccbProcessQueues,
          ...normalizeProcessQueueData(action.payload.data),
        ];
      } else {
        concatenateCcbProcessQueue = [...state.ccbProcessQueueStatus];
      }
      const filteredCcbPQ = lodash.uniqBy(concatenateCcbProcessQueue, "id");
      return {
        ...state,
        ccbProcessQueues: filteredCcbPQ,
        ccbProcessQueueStatus: "success",
      };
    }

    case ACTION_TYPES.SET_CCB_PROCESS_QUEUES_ERROR: {
      return {
        ...state,
        ccbProcessQueueStatus: "error",
      };
    }

    case ACTION_TYPES.SET_MAILCHIMP_TAGS: {
      return {
        ...state,
        mailchimpTagsStatus: "loading",
      };
    }

    case ACTION_TYPES.SET_MAILCHIMP_TAGS_SUCCESS: {
      const formData = convertFormdataToJson(action.data);
      let tags = state.mailchimpTags;
      let fetchedTagsIds = state.mailchimpListIdForTags;
      if (action.payload.status === 1) {
        if (!state.mailchimpListIdForTags.includes(formData.mailchimpListId)) {
          tags[formData.mailchimpListId] = addNewKeyPairInArray(
            Object.values(action.payload.data),
            {
              active: false,
            }
          );
          fetchedTagsIds.push(formData.mailchimpListId);
        }
      }
      return {
        ...state,
        mailchimpTags: tags,
        mailchimpTagsStatus: action.payload.status === 1 ? "success" : "error",
        mailchimpListIdForTags: [...new Set(fetchedTagsIds)],
      };
    }

    case ACTION_TYPES.SET_MAILCHIMP_TAGS_ERROR: {
      return {
        ...state,
        mailchimpTagsStatus: "error",
      };
    }

    case ACTION_TYPES.REMOVE_MULTIPLE_MC_TO_CCB_PAIR: {
      return {
        ...state,
        removeMultipleMcToCcbPairStatus: "loading",
      };
    }

    case ACTION_TYPES.REMOVE_MULTIPLE_MC_TO_CCB_PAIR_SUCCESS: {
      let mcPairs = [];
      if (action.payload.status === 1) {
        mcPairs = state.mcToCcbpairs.filter(
          (item) => !action.ids.includes(item.id)
        );
      } else {
        mcPairs = state.mcToCcbpairs;
      }
      return {
        ...state,
        removeMultipleMcToCcbPairStatus:
          Number(action.payload.status) === 1 ? "success" : "error",
        mcToCcbpairs: mcPairs,
      };
    }

    case ACTION_TYPES.REMOVE_MULTIPLE_MC_TO_CCB_PAIR_ERROR: {
      return {
        ...state,
        removeMultipleMcToCcbPairStatus: "error",
      };
    }

    case ACTION_TYPES.REMOVE_MULTIPLE_MC_TO_CCB_PAIR_CLEAR: {
      return {
        ...state,
        removeMultipleMcToCcbPairStatus: "",
      };
    }

    case ACTION_TYPES.REMOVE_MULTIPLE_CCB_TO_MC_PAIR: {
      return {
        ...state,
        removeMultipleCcbToMcPairStatus: "loading",
      };
    }

    case ACTION_TYPES.REMOVE_MULTIPLE_CCB_TO_MC_PAIR_SUCCESS: {
      const formData = convertFormdataToJson(action.data);
      let allCcbGroups = state.ccbGroups;
      let allCcbSavedSearch = state.ccbSavedSearches;
      let allCcbSyncedSavedSearch = state.ccbSyncedSavedsearches;
      if (action.payload.status === 1) {
        if (!lodash.isEmpty(allCcbGroups) && formData.type === GROUPS) {
          allCcbGroups = allCcbGroups.filter(
            (item) => !action.ids.includes(item.id)
          );
        }
        if (
          !lodash.isEmpty(allCcbSavedSearch) &&
          !lodash.isEmpty(allCcbSyncedSavedSearch) &&
          formData.type === SEARCH
        ) {
          allCcbSavedSearch = allCcbSavedSearch.filter(
            (item) => !action.ids.includes(item.id)
          );
          allCcbSyncedSavedSearch = allCcbSyncedSavedSearch.filter(
            (item) => !action.ids.includes(item.id)
          );
        }
      }
      return {
        ...state,
        removeMultipleCcbToMcPairStatus:
          Number(action.payload.status) === 1 ? "success" : "error",
        ccbGroups: lodash.uniqBy(allCcbGroups, "id"),
        ccbSavedSearches: lodash.uniqBy(allCcbSavedSearch, "id"),
        ccbSyncedSavedsearches: lodash.uniqBy(allCcbSyncedSavedSearch, "id"),
      };
    }

    case ACTION_TYPES.REMOVE_MULTIPLE_CCB_TO_MC_PAIR_ERROR: {
      return {
        ...state,
        removeMultipleCcbToMcPairStatus: "error",
      };
    }

    case ACTION_TYPES.REMOVE_MULTIPLE_CCB_TO_MC_PAIR_CLEAR: {
      return {
        ...state,
        removeMultipleCcbToMcPairStatus: "",
      };
    }

    case ACTION_TYPES.SET_HAS_NOT_SYNCED_PAIRS: {
      return {
        ...state,
        hasNotSyncedPairs: action.payload,
      };
    }

    case ACTION_TYPES.MC_TO_CCB_DISABLE_INACTIVE_REMOVE_FOR: {
      return {
        ...state,
        mcToCcbDisableInactiveRemoveFor: action.payload,
      };
    }

    case ACTION_TYPES.HAS_SAVED_SEARCH_PERMISSIONS: {
      return {
        ...state,
        hasSavedSearchPermissionsStatus: "loading",
      };
    }

    case ACTION_TYPES.HAS_SAVED_SEARCH_PERMISSIONS_SUCCESS: {
      return {
        ...state,
        hasSavedSearchPermissions: action.payload.status === 1,
        hasSavedSearchPermissionsStatus: "success",
      };
    }

    case ACTION_TYPES.HAS_SAVED_SEARCH_PERMISSIONS_ERROR: {
      return {
        ...state,
        hasSavedSearchPermissionsStatus: "error",
      };
    }

    case ACTION_TYPES.HAS_SAVED_SEARCH_PERMISSIONS_CLEAR: {
      return {
        ...state,
        hasSavedSearchPermissionsStatus: "",
        hasSavedSearchPermissions: false,
      };
    }

    case ACTION_TYPES.SET_INTEGRATION_PAIR_FOR_EDIT: {
      return {
        ...state,
        integrationPairForEdit: action.payload,
        showAddNewSyncPairModal: lodash.isEmpty(action.payload) ? false : true,
      };
    }

    default:
      return state;
  }
};

export default integrationReducer;
